export const resendEmailVerification = {
  order: 2,
  name: "Resend email verification",
  subcategory: "Manage your account",
  markdown: `# Resend email verification
  Are you having a hard time finding the email verification link that Tower Hunt sends? Read on for steps to resend it.

  {% callout type="info" marginStyles="my-4" %}
  Note: You'll need access to the email inbox associated with your Tower Hunt account. If you don't have access, {% inlineRouterLink articleId="change-your-email-address" %}learn how to change your account email address{% /inlineRouterLink %}.
  {% /callout %}

  {% tablessContainer %}
  1. [Sign in to Tower Hunt](/sign-in).
  2. Click **Resend confirmation** within the black bar across the top of the page.
  3. {% inlineRouterLink articleId="verify-your-email-address" %}Verify your email address{% /inlineRouterLink %}.
  {% /tablessContainer %}`,
};
